import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
// 侧边栏重复点击报错(路由重复)
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
const routes = [
  { path: '/', redirect: '/login' },

  // 登录
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/login.vue')
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import(/* webpackChunkName: "login" */ '../views/home/welcome.vue')
  },

  //新闻
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "login" */ '../views/home/news.vue')
  },
  {
    path: '/home',
    name: 'ViewContainer',
    redirect: '/home/dataOverview',
    component: () => import(/* webpackChunkName: "fir" */ '../views/home/home.vue'),
    children: [
      // 跳转弹窗
      {
        path: '/manage/first',
        name: 'first',
        component: () => import(/* webpackChunkName: "fir" */ '../views/system/mapInfo.vue')
      },
      // 快捷方式查看企业
      {
        path: '/home/fastCompany',
        name: 'fastCompany',
        component: () => import(/* webpackChunkName: "login" */ '../views/fastCompany/fastCompany.vue')
      },
      // 数据概览
      {
        path: '/home/dataOverview',
        name: 'dataOverview',
        component: () => import(/* webpackChunkName: "fir" */ '../views/data/dataOverview.vue')
      },
      // 操作日志
      {
        path: '/manage/handleLog',
        name: 'handleLog',
        component: () => import(/* webpackChunkName: "fir" */ '../views/manage/handleLog.vue')
      },
      // 企业列表(改为大数据)
      {
        path: '/manage/bigData',
        // path: '/manage/companyList',
        name: 'companyList',
        component: () => import(/* webpackChunkName: "fir" */ '../views/manage/companyList.vue')
      },
      // 第三方系统
      {
        path: '/manage/others',
        name: 'others',
        component: () => import(/* webpackChunkName: "fir" */ '../views/manage/others.vue'),
      },
      // 企业列表 (第三方系统下的)
      {
        path: '/manage/otherCompanyList',
        name: 'otherCompanyList',
        component: () => import(/* webpackChunkName: "fir" */ '../views/manage/otherCompanyList.vue')
      },

      // 大数据搜索(改为邦泰企业列表)
      {
        // path: '/manage/bigData',
        path: '/manage/companyList',
        name: 'bigData',
        component: () => import(/* webpackChunkName: "fir" */ '../views/manage/bigData.vue')
      },
      // 风险点辨识
      {
        path: '/danger/recognize',
        name: 'recognize',
        component: () => import(/* webpackChunkName: "sec" */ '../views/danger/recognize.vue')
      },
      // 作业活动清单
      {
        path: '/danger/workList',
        name: 'workList',
        component: () => import(/* webpackChunkName: "sec" */ '../views/danger/workList.vue')
      },
      // 作业活动评价清单
      {
        path: '/danger/workEvaluate',
        name: 'workEvaluate',
        component: () => import(/* webpackChunkName: "sec" */ '../views/danger/workEvaluate.vue')
      },
      // 设备设施清单
      {
        path: '/danger/equipment',
        name: 'equipment',
        component: () => import(/* webpackChunkName: "sec" */ '../views/danger/equipment.vue')
      },
      // 设备设施风险评价清单
      {
        path: '/danger/equipmentRisk',
        name: 'equipmentRisk',
        component: () => import(/* webpackChunkName: "sec" */ '../views/danger/equipmentRisk.vue')
      },
      // 隐患排查清单
      {
        path: '/danger/dangerCheck',
        name: 'dangerCheck',
        component: () => import(/* webpackChunkName: "sec" */ '../views/danger/dangerCheck.vue')
      },
      // 隐患指派人管理
      {
        path: '/danger/dangerPeople',
        name: 'dangerPeople',
        component: () => import(/* webpackChunkName: "sec" */ '../views/danger/dangerPeople.vue')
      },
      // 巡检班次任务
      {
        path: '/examine/examineNum',
        name: 'examineNum',
        component: () => import(/* webpackChunkName: "thr" */ '../views/examine/examineNum.vue')
      },
      // 巡检记录
      {
        path: '/examine/examineHistory',
        name: 'examineHistory',
        component: () => import(/* webpackChunkName: "thr" */ '../views/examine/examineHistory.vue')
      },
      // 双重预防-考试管理
      {
        path: '/preventEducation/test',
        name: 'preventEducationTest',
        component: () => import(/* webpackChunkName: "thr" */ '../views/preventEducation/test.vue')
      },
      // 双重预防-会议记录
      {
        path: '/preventEducation/train',
        name: 'preventEducationTrain',
        component: () => import(/* webpackChunkName: "thr" */ '../views/preventEducation/train.vue')
      },
      // 双重预防-学习文库
      {
        path: '/preventEducation/study',
        name: 'preventEducationStudy',
        component: () => import(/* webpackChunkName: "thr" */ '../views/preventEducation/study.vue')
      },
      // 双重预防-学习视频
      {
        path: '/preventEducation/video',
        name: 'preventEducationVideo',
        component: () => import(/* webpackChunkName: "thr" */ '../views/preventEducation/video.vue')
      },
      // 区域网格
      {
        path: '/system/areaGridding',
        name: 'areaGridding',
        component: () => import(/* webpackChunkName: "thr" */ '../views/system/areaGridding.vue')
      },
      //地图信息
      {
        path: '/system/mapInfo',
        name: 'mapInfo',
        component: () => import(/* webpackChunkName: "thr" */ '../views/system/mapInfo.vue')
      },
      // 通知公告
      {
        path: '/system/message',
        name: 'message',
        component: () => import(/* webpackChunkName: "thr" */ '../views/system/message.vue')
      },
      // 首页轮播
      {
        path: '/system/carousel',
        name: 'carousel',
        component: () => import(/* webpackChunkName: "thr" */ '../views/system/carousel.vue')
      },
      // 首页链接
      {
        path: '/system/indexLink',
        name: 'indexLink',
        component: () => import(/* webpackChunkName: "thr" */ '../views/system/indexLink.vue')
      },
      // 绑定企业(自己用)
      {
        path: '/system/bindCompany',
        name: 'bindCompany',
        component: () => import(/* webpackChunkName: "thr" */ '../views/system/bindCompany.vue')
      },

      // 教育培训-课件管理
      {
        path: '/education/classManagement',
        name: 'classManagement',
        component: () => import(/* webpackChunkName: "thr" */ '../views/education/classManagement.vue')
      },

      // 执法工作-企业巡检率
      {
        path: '/enforce/polling',
        name: 'polling',
        component: () => import(/* webpackChunkName: "thr" */ '../views/enforce/polling.vue')
      },

      //考试管理
      {
        path: '/education/testManagement',
        name: 'makeTest',
        component: () => import(/* webpackChunkName: "thr" */ '../views/education/testManagement.vue')
      },
      //会议记录
      {
        path: '/education/makeTest',
        name: 'makeTest',
        component: () => import(/* webpackChunkName: "thr" */ '../views/education/makeTest.vue')
      },
      //学习文库
      {
        path: '/education/makeTestRule',
        name: 'makeTestRule',
        component: () => import(/* webpackChunkName: "thr" */ '../views/education/makeTestRule.vue')
      },
      //学习视频
      {
        path: '/education/testHistory',
        name: 'testHistory',
        component: () => import(/* webpackChunkName: "thr" */ '../views/education/testHistory.vue')
      },

      //执法计划
      {
        path: '/enforce/enforcePlan',
        name: 'makeenforcePlanTest',
        component: () => import(/* webpackChunkName: "thr" */ '../views/enforce/enforcePlan.vue')
      },
      //执法记录
      {
        path: '/enforce/enforceHistory',
        name: 'enforceHistory',
        component: () => import(/* webpackChunkName: "thr" */ '../views/enforce/enforceHistory.vue')
      },
      //储罐实时监控
      {
        path: '/monitoring/jar',
        name: 'jar',
        component: () => import(/* webpackChunkName: "thr" */ '../views/monitoring/jar.vue')
      },
      //气体浓度实时监控
      {
        path: '/monitoring/gas',
        name: 'gas',
        component: () => import(/* webpackChunkName: "thr" */ '../views/monitoring/gas.vue')
      },
      //视频实时监控
      {
        path: '/monitoring/video',
        name: 'video',
        component: () => import(/* webpackChunkName: "thr" */ '../views/monitoring/video.vue')
      },
    ]
  }
]



const router = new VueRouter({
  mode: 'history',
  routes
})

// 路由拦截
router.beforeEach((to, from, next) => {
  if (
    to.name == 'login' || to.name == 'fastCompany'
  )
    return next()
  const tokenStr = localStorage.getItem('btlh_token')
  // 为空返回登陆
  if (!tokenStr) {
    return next('/login')
  }
  // 不为空不拦截
  next()
})
export default router

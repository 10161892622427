import Vue from "vue";
 //高德离线地图
 import App from '../App.vue'
 import VueAMap from 'vue-amap';
 Vue.use(VueAMap);
 VueAMap.initAMapApiLoader({
   //高德key
   key: 'ed874656da9395f37265c6814a45e7e7',
   //插件引入
   plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType',
     'AMap.PolyEditor', 'AMap.CircleEditor','AMap.Geocoder','AMap.Marker','AMap.Geolocation','AMap.MouseTool','AMap.Text','AMap.Polygon','AMap.OverlayGroup','AMap.LngLat','AMap.ImageLayer'
   ],
   v: '1.4.15',
   uiVersion: '1.0.11'
 })
